import { NgModule } from '@angular/core';
import { CommonLibModule, StorageService } from 'common-lib';
import { ComponentService } from './services/component.service';
import { DataModalComponent } from './components/data-modal/data-modal.component';
//import { TimeAgoPipe } from 'time-ago-pipe';
import { PushNotificationErrorComponent } from './components/push-notification-error/push-notification-error.component';
import { PushNotificationSuccessComponent } from './components/push-notification-success/push-notification-success.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { NgxUploaderModule } from 'ngx-uploader';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
//(RD)PMB-7380 Modify date selection on the order list screen
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { ProcurantHeaderComponent } from './components/procurant-header/procurant-header.component';
import { CustomDataModalComponent } from './components/custom-data-modal/custom-data-modal.component';
import { ProcurantOverlayDesignComponent } from './components/procurant-overlay-design/procurant-overlay-design.component';
import { ProcurantChargesOverlayContainerComponent } from './components/procurant-charges-overlay/procurant-charges-overlay-container/procurant-charges-overlay-container.component';
import { ChargesOverlayListComponent } from './components/procurant-charges-overlay/charges-overlay-list/charges-overlay-list.component';
import { AddChargesOverlayComponent } from './components/procurant-charges-overlay/add-charges-overlay/add-charges-overlay.component';
import { EditChargesOverlayComponent } from './components/procurant-charges-overlay/edit-charges-overlay/edit-charges-overlay.component';

import { NgxMaskDirective, NgxMaskModule } from 'ngx-mask';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

@NgModule({
    imports: [
        CommonLibModule,
        NotificationModule,
        NgxUploaderModule,
        FlexLayoutModule,
        TooltipsModule,
        ButtonsModule,
        DateInputsModule,
        NgxMaskModule.forRoot(),
        IndicatorsModule,
    ],
    declarations: [
        DataModalComponent,
        PushNotificationErrorComponent,
        PushNotificationSuccessComponent,
        CommonDialogComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        ProcurantLoadingComponent,
        //(RD)PMB-7380 Modify date selection on the order list screen
        CustomDatePickerComponent,
        ProcurantHeaderComponent,
        CustomDataModalComponent,
        ProcurantOverlayDesignComponent,
        ProcurantChargesOverlayContainerComponent,
        ChargesOverlayListComponent,
        AddChargesOverlayComponent,
        EditChargesOverlayComponent,
        FilterCardComponent,
        OrderCardComponent
    ],
    exports: [
        CommonLibModule,
        DataModalComponent,
        PushNotificationErrorComponent,
        PushNotificationSuccessComponent,
        NgxUploaderModule,
        CommonDialogComponent,
        ProcurantLoadingComponent,
        FlexLayoutModule,
        TooltipsModule,
        ButtonsModule,
        DateInputsModule,
        //(RD)PMB-7380 Modify date selection on the order list screen
        CustomDatePickerComponent,
        ProcurantHeaderComponent,
        CustomDataModalComponent,
        ProcurantOverlayDesignComponent,
        ProcurantChargesOverlayContainerComponent,
        ChargesOverlayListComponent,
        AddChargesOverlayComponent,
        EditChargesOverlayComponent,
        NgxMaskModule,
        FilterCardComponent,
        OrderCardComponent
    ],
    providers: [StorageService, ComponentService],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class SharedModule { }

export const UnitCostWarnigModel = Object.freeze({
    value: 1000
});

export const UserMannual = Object.freeze({
    USER_MANNUAL: 'https://www.procurant.com/hubfs/Supplier%20Info%20Center/Supplier%20resources/Supplier%20Order%20Details%20User%20Guide.pdf'
});
