<!-- Old code comment -->
<!-- <form [formGroup]="parentForm" class="custom-date">
  <mat-form-field appearance="outline" [formGroup]="parentForm">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [matDatepicker]="picker" id="{{ID}}" [min]="minDate" [max]="maxDate" placeholder="{{placeholder}} "
      formControlName="{{field}}" (dateChange)="dateChangeEvent($event.target.value)"
      (click)="picker.open();closeOverlay()">    
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="closeOverlay()"></mat-datepicker-toggle>
  </mat-form-field>
</form> -->

<!-- New code implement for date picker  (Date:20-12-2023) -->
<form [formGroup]="parentForm" [ngClass]="(!clickOnDate)?'custom-date':'header-date'">
  <mat-form-field [floatLabel]="!isMultipleShipDate?'always':''" appearance="outline" [formGroup]="parentForm"
    [ngClass]="disabledPicker?'readOnlyFormField':''" [ngClass]="(!isMultipleShipDate)?'widthChange':''"
    [class]="customeClass">
    <mat-label *ngIf="isDisplayLabel">{{placeholder}}</mat-label>
    <input matInput [matDatepicker]="picker" id="{{ID}}" [min]="minDate" [max]="maxDate"
      placeholder="{{multipleShipDateRange}}" formControlName="{{field}}"
      (dateChange)="dateChangeEvent($event.target.value)" (click)="picker.open();closeOverlay()">
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matIconSuffix [for]="picker" (click)="closeOverlay()"></mat-datepicker-toggle>
    <button *ngIf="previousValue" mat-icon-button matSuffix position="bottom" kendoTooltip title="{{previousData}}"
      class="date-history-icon-container">
      <img src="assets/images/history-rounded.svg">
    </button>

    <!--   <div matSuffix *ngIf="previousValue" class="history-icon-container">
    <div position="bottom" kendoTooltip title="{{previousData}}" class="icon-image">
    </div>
  </div> -->
    <mat-error *ngIf="error != ''">
      {{error}}
    </mat-error>
  </mat-form-field>
  <!-- <mat-error class="trace-error" *ngIf="isTraceError"> {{ 'TRACK_TRACE.MESSAGE.ORDER_START_DATE_ERROR' | translate }}
  </mat-error> -->
</form>
<!-- End here -->