import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChargesService } from '../../../services/charges.service';
import { ChargesOverlayListComponent } from '../charges-overlay-list/charges-overlay-list.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PO_MODFLAG, constant, SUBSCRIBED_APPS, MODULE_CODE, FEATURE_CODE, TranslateService } from 'common-lib';
import { OneNotifyService } from 'one-auth';

@Component({
  selector: 'edit-charges-overlay',
  templateUrl: './edit-charges-overlay.component.html',
  styleUrls: ['./edit-charges-overlay.component.scss']
})
export class EditChargesOverlayComponent implements OnInit {
  // (RD)PMB-7504 supplier order details UI change

  @Input() order: any;
  chargePRORATE_BY: any[];
  chargeAPPLY_BY: any[];
  @Input() data: any;;
  isShowProrateToLine = true;
  @Input() parent: FormGroup;
  @Input() index: any;
  editForm: FormGroup;
  orderData: any;
  page: any;
  isStatic: boolean = false;
  //(PM)PMB-7631 Add ability to default charge apply by and prorate by settings for a buying company
  applyAs: string;
  permissions: boolean = true;
  isHideAddCharges: boolean;
  isPermissionFetched: boolean = false;
  applyByCode = 'FLAT';
  partnerOrgId: any;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private fb: FormBuilder, private cdr: ChangeDetectorRef, private service: ChargesService, private chargesOverlayList: ChargesOverlayListComponent, private notify: OneNotifyService, private transalate: TranslateService) { }

  ngOnInit(): void {
    this.isShowProrateToLine = this.data[1];
    this.partnerOrgId = this.data[6];
    this.orderData = this.parent.get('editChargesArray')['controls'][this.index];
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    let name = this.orderData.value.chargeName + ' | ' + this.orderData.value.chargeTypeCode + ((this.orderData.value.freight == true) ? ' - Freight' : '');
    this.editForm = this.fb.group({
      id: [this.orderData.value.id],
      poId: [this.orderData.value.poId],
      poLineId: [this.orderData.value.poLineId],
      chargeId: [this.orderData.value.chargeId],
      chargeName: [this.orderData.value.chargeName],
      applyOnModuleCode: [this.orderData.value.applyOnModuleCode],
      displayToAppCode: [this.orderData.value.displayToAppCode],
      companyName: [this.orderData.value.companyName],
      displayToSupplier: [this.orderData.value.displayToSupplier],
      name: [name, Validators.compose([Validators.required])],
      chargeAmt: [this.orderData.value.chargeAmt, Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0), Validators.required])],
      chargeTypeId: [this.orderData.value.chargeTypeId],
      chargeTypeCode: [this.orderData.value.chargeTypeCode],
      chargeCode: [this.orderData.value.chargeCode],
      applyByCode: [this.orderData.value.applyByCode, Validators.compose([Validators.required])],
      inclProdCost: [this.orderData.value.inclProdCost],
      prorateById: [this.orderData.value.prorateById],
      prorateByCode: [this.orderData.value.prorateByCode, this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [this.orderData.value.addedBy],
      freight: [this.orderData.value.freight],
      tariffRate: [this.orderData.value.tariffRate]
    });
    this.getChargeSetting();
  }

  // getChargeSetting() {
  //   this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
  //     this.chargeAPPLY_BY = resp;
  //   })

  //   this.service.getChargeSetting("PRORATE_BY").subscribe(resp => {
  //     this.chargePRORATE_BY = resp;
  //   })
  // }

  getChargeSetting() {
    console.log('in edit');
    this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
      this.chargeAPPLY_BY = resp;
      this.service.getChargeSetting("PRORATE_BY").subscribe(resp1 => {
        this.chargePRORATE_BY = resp1;
        this.isHideAddCharges = this.data[4];
        //(OL) PMB-7713 issue fix for setting defaukt to FLAT
        this.isPermissionFetched = true;
      })
    })
  }

  changeValue() {
    this.chargesOverlayList.editFlag = false;
    //(PM) PMB-10447 PO charges should be visible on Invoices and editable
    if (this.editForm.value.addedBy == 'SUPPLIER') {
      this.service.getMappedChargesForSup(this.editForm.value.chargeId, this.partnerOrgId).subscribe(resp => {
        let ChargeResp = resp;
        if (this.editForm.value.displayToAppCode == 'BUYER' && ChargeResp == null) {
          let chargeName = this.editForm.value.chargeName
          let msg = constant.replaceErrorMsgValue(this.transalate.instant('DETAIL_PAGE.MESSAGE.UNMAPPED_CHARGES_ERROR_MSG'), chargeName);
          this.notify.show(msg, 'error')
          this.chargesOverlayList.isChargeMappedFlag = true;
        } else {
          this.chargesOverlayList.isChargeMappedFlag = false;
        }
      }, (err) => {
        this.notify.show(err, 'error')
      })
    }
    this.chargesOverlayList.changeValue(this.editForm.value, this.index);
  }
}
