import { environment } from './environment';

export const URLS = Object();

//Jones: Add all API endpoints here

// Messages API's
URLS.MessagesListAPI = environment.BASE_API_URL + "order/dashboard/sup";
URLS.MessagesPostAPI = environment.BASE_API_URL + ""; //TODO : Add API Endpoint when available
URLS.MessagesDetailAPI = environment.BASE_API_URL + "order/dashboard/details/";

// Reminders
// URLS.AddReminderAPI = environment.BASE_API_URL + "";
// URLS. = environment.BASE_API_URL + "";

// User Activity API's
//URLS.UserActivityListAPI = environment.BASE_API_URL + "order/dashboard/user-activity";
URLS.UserActivityListAPI = environment.BASE_API_URL + "notification/api/message/user-activity";
URLS.UserLogout = environment.BASE_API_URL + "auth/api/user/logout-internal";

// Reminders API's
URLS.AddReminderAPI = environment.BASE_API_URL + "order/reminder/create";
URLS.ReminderDetailsAPI = environment.BASE_API_URL + "order/reminder/find/";
URLS.UpdateReminderAPI = environment.BASE_API_URL + "order/reminder/update";
URLS.GetReminderListAPI = environment.BASE_API_URL + "order/reminder/list";
URLS.DeleteReminderAPI = environment.BASE_API_URL + "order/reminder/remove";
URLS.EnableReminderAPI = environment.BASE_API_URL + "order/reminder/enable";
URLS.DeleteDismissReminderAPI = environment.BASE_API_URL + "order/reminder/remove";
URLS.GetProfileImage = environment.BASE_API_URL + "admin/api/object-store/view/"

// Logistic Service
URLS.GetOrderCommentCount = environment.BASE_API_URL + "order/comment/count/PO";
URLS.AssignedModelListAPI = environment.BASE_API_URL + "logistics/load/transmodes";
URLS.TradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/SUPPLIER/CARRIER";
URLS.equipmentAPI = environment.BASE_API_URL + "logistics/load/equipment-types";
URLS.GetOrderDetailsForLoad = environment.BASE_API_URL + "order/po/short/";
URLS.GetLoadDetails = environment.BASE_API_URL + "order/po/get-by-poId";
URLS.GetLoadDetailsByLoadId = environment.BASE_API_URL + "logistics/load/find/";
URLS.GetLoadShipment = environment.BASE_API_URL + "logistics/load/getLoadShipment";
URLS.GetLoadTracking = environment.BASE_API_URL + "logistics/load/getLoadTracking";

URLS.LoadCreateAPI = environment.BASE_API_URL + "logistics/load/create-sup-load";
URLS.LoadUpdateAPI = environment.BASE_API_URL + "logistics/load/create-sup-load";
URLS.LoadCanceledAPI = environment.BASE_API_URL + "logistics/load/cancel-sup-load";
URLS.LoadFindAPI = environment.BASE_API_URL + "logistics/load/find/";
URLS.LoadCancel = environment.BASE_API_URL + "logistics/load/cancel";
URLS.RemoveOrderFromLoad = environment.BASE_API_URL + "logistics/load/remove/loadPOs";
URLS.GetLogisticsAppModuleAccessAPI = environment.BASE_API_URL + "logistics/load/businessRules";
URLS.GetLoadHeaderIndicators = environment.BASE_API_URL + "logistics/load/load-indicator/";



// Order Service
URLS.GetOrdersAPI = environment.BASE_API_URL + "order/sup/list-new";
URLS.GetShipperOrderList = environment.BASE_API_URL + "order/sup/cross-dock-order-list-new";
URLS.ShipsFromBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/";
URLS.SearchOrderNumber = environment.BASE_API_URL + "order/sup/search/suggest/";
URLS.SearchOrder = environment.BASE_API_URL + "order/sup/search/";
URLS.UpdateOrder = environment.BASE_API_URL + "order/sup/po/update";
URLS.GetOrderDetails = environment.BASE_API_URL + "order/sup/po/";
URLS.GetCDOrderDetails = environment.BASE_API_URL + "order/cross-dock/po/";
URLS.GetProduct = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemPrimary/";
URLS.GetOrgListDetails = environment.BASE_API_URL + "admin/api/org/getBizUnitShortDetails";
URLS.GetOrgsDetails = environment.BASE_API_URL + "admin/api/org/getOrganizationShortDetails";
// URLS.GetCountryList = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetMappedProduct = environment.BASE_API_URL + "catalog/api/catalog/fetch-partner-catalog-by-catalog-ids";
URLS.GetCountryList = environment.BASE_API_URL + "catalog/api/catalog/origin";
URLS.UpdateTraceInfo = environment.BASE_API_URL + "order/sup/po/line-product/update-trace-info";
URLS.UpdateLineProduct = environment.BASE_API_URL + "order/sup/po/line-product/update";
URLS.CatalogCodeExists = environment.BASE_API_URL + "catalog/api/catalog/";
URLS.GTINExists = environment.BASE_API_URL + "catalog/api/catalog/check-if-gtin-exist";
URLS.UPCExists = environment.BASE_API_URL + "catalog/api/catalog/check-upc-exist/";
URLS.GetOrderHistory = environment.BASE_API_URL + "order/sup/history/";
URLS.GetPoLineHistory = environment.BASE_API_URL + "order/sup/poline/history/";
URLS.CheckParellelTransactions = environment.BASE_API_URL + "order/po/check-parellel-transactions/";

URLS.AcceptPOLineItem = environment.BASE_API_URL + "order/dashboard/accept";
URLS.SendPOLineItem = environment.BASE_API_URL + "order/dashboard/send";
URLS.SONumberExistsAPI = environment.BASE_API_URL + "order/po/exists-by-sonumber/";
URLS.BRRules = environment.BASE_API_URL + "order/po/businessRules";
URLS.shipFromCD = environment.BASE_API_URL + "order/sup/po/ship-from-cd/";

URLS.GetMessageListAPI = environment.BASE_API_URL + "notification/api/message/get";
URLS.SetMessageAsReadAPI = environment.BASE_API_URL + "notification/api/message/read/";
URLS.DissmisMessageAPI = environment.BASE_API_URL + "notification/api/message/dismiss";

URLS.SearchProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc-active/";
URLS.GetProductCodeDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/search-code-desc-active/";
URLS.SupplierAddLineAPI = environment.BASE_API_URL + "order/sup/add-line";
URLS.GetPartnerMappedProductDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/search-partner-mapped-product/";
URLS.GetLatestCommentAPI = environment.BASE_API_URL + "order/comment/get-latest-comment";

//new API for Order Service
URLS.UpdateOrderNewAPI = environment.BASE_API_URL + "order/sup/po/update-new";
URLS.GetPOOrderDetails = environment.BASE_API_URL + "order/sup/po/";
URLS.GetPOOrderHeaderDetails = environment.BASE_API_URL + "order/sup/po-header/";
URLS.GetPOHeaderIndicators = environment.BASE_API_URL + "order/sup/po-indicator/";
URLS.GetPOLineSummary = environment.BASE_API_URL + "order/po/po-total-summary/";
URLS.GetPOOrderLineDetails = environment.BASE_API_URL + "order/sup/po-line/"
URLS.GetPOOrderLineDetailsPaging = environment.BASE_API_URL + "order/sup/po-line-page";
URLS.POHeaderCharges = environment.BASE_API_URL + "order/sup/header-charge/"
URLS.POLineCharges = environment.BASE_API_URL + "order/sup/line-charge/"
URLS.CancelledPOMarkedAsViewedAPI = environment.BASE_API_URL + "order/po/flag-cancelled-po";
URLS.GetSplitOrderAPI = environment.BASE_API_URL + "order/sup/split-order";
URLS.getSplitPoFlag = environment.BASE_API_URL +  "order/buy/get-split-po-flag";
URLS.GrowingMethod = environment.BASE_API_URL + "order/sup/po-line/growing-method"
//getting freight rate information...
URLS.GetFreightRateAPI = environment.BASE_API_URL + "order/sup/getFreightRate";

//Get Org all business rules
URLS.GetOrgAllBusinessRulesAPI = environment.BASE_API_URL + "admin/api/access/fetch-org-businessRules/";

URLS.PaymentServiceBRRules = environment.BASE_API_URL + "payment/invoice/businessRules";

URLS.SearchOrderByFiltersAPI = environment.BASE_API_URL + "order/sup/search/suggest/by-filter/";
URLS.SearchCrossDockOrderByFiltersAPI = environment.BASE_API_URL + "order/sup/search/suggest/cross-dock/by-filter/";
URLS.SearchOrderListByFiltersAPI = environment.BASE_API_URL + "order/sup/search-by-contents"
URLS.GetBuyerListFromReport = environment.BASE_API_URL + "admin/api/user/list/";
URLS.GetDeliveryToAPI = environment.BASE_API_URL + "admin/api/org/trading-partner/businessUnits/";
URLS.GetOrderStatus = environment.BASE_API_URL + "order/po/active-order-statuses";
URLS.GetCommodities = environment.BASE_API_URL + "catalog/api/catalog/my-commodities";
URLS.GetBuyerUser = environment.BASE_API_URL + "admin/api/user/buyers-user-list/ORDER_MANAGEMENT?selectedBuyersIds="
URLS.GetLotCode = environment.BASE_API_URL + "pti/api/pti/get-harvest-info"
URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
URLS.GetTraceInfo = environment.BASE_API_URL + "order/sup/get-trace-info/true/";
URLS.UpdateTraceInfo = environment.BASE_API_URL + "order/sup/po/line-product/update-trace-info-new";

//My View on Create Load
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter";
URLS.DeleteFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/delete/filter";
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";

//Invoice Service
//URLS.GetInvoices = environment.BASE_API_URL + "payment/invoice/list"; Old invoice list API
URLS.GetInvoices = environment.BASE_API_URL + "payment/invoice/list-new";
URLS.GetPaymentTerms = environment.BASE_API_URL + "admin/api/paymentterm/list";
URLS.SuggestInvoiceNumber = environment.BASE_API_URL + "payment/invoice/sup/search/suggest/";
URLS.SearchInvoice = environment.BASE_API_URL + "payment/invoice/sup/search-by-invoiceid/";
URLS.UpdateInvoice = environment.BASE_API_URL + "payment/invoice/update";
URLS.GetInvoiceDetails = environment.BASE_API_URL + "payment/invoice/";
URLS.GetInvoiceFormPoId = environment.BASE_API_URL + "payment/invoice/by-poid/";
URLS.InvoiceNumberExistsAPI = environment.BASE_API_URL + "payment/invoice/exists-by-invoiceNumber/"
URLS.GetShipFromAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/SHIP_FROM";
URLS.GetInvoiceStatements = environment.BASE_API_URL + "payment/invoice/get-invoice-statements";
URLS.AddUpdateInvoiceStatement = environment.BASE_API_URL + "payment/invoice/add-update-invoice-statement";
//URLS.GetRemitToList = environment.BASE_API_URL + "admin/api/org/businessUnits/";
URLS.GetRemitToList = environment.BASE_API_URL + "admin/api/org/getOrganizationByBizUnit";
URLS.GetInvoiceHistory = environment.BASE_API_URL + "payment/invoice/history/";
URLS.GetInvoiceLineHistory = environment.BASE_API_URL + "payment/invoice/invoiceline/history/";
URLS.InvoiceHeaderCharges = environment.BASE_API_URL + "payment/invoice/header-charge/";
URLS.InvoiceLineCharges = environment.BASE_API_URL + "payment/invoice/line-charge/";
//(RP)PMB-7118
URLS.GetInvoiceProcessStatus = environment.BASE_API_URL + "edi/api/profile/invoice-doc-process-status";
//Invoice Charges
URLS.setInvoiceIndicatorAsViwedAPI = environment.BASE_API_URL + "payment/invoice/invoice-indicator/delete";

// Task Center Service
URLS.GetTabCount = environment.BASE_API_URL + "order/sup/taskcenter"
URLS.GetOrderTabCount = environment.BASE_API_URL + "order/sup/taskcenter/"
URLS.GetOrderHeaderAPI = environment.BASE_API_URL + "order/po/taskcenter/";
URLS.GetOrderTaskCenterAPI = environment.BASE_API_URL + "order/po/taskcenter";
URLS.GETOrderLineDetailsAPI = environment.BASE_API_URL + "order/po/taskcenter/";
URLS.SendAcceptAPI = environment.BASE_API_URL + "order/po/taskcenter/submit";
URLS.ShipOrderAPI = environment.BASE_API_URL + "order/po/taskcenter/ship";
URLS.TaskcenterCountAPI = environment.BASE_API_URL + "payment/invoice/taskcenter-count-metadata?taskcenterTabs=";
URLS.ReceiveMissmatchAPI = environment.BASE_API_URL + "payment/invoice/invoice-taskcenter";
URLS.ReceiveMissmatchPoAPI = environment.BASE_API_URL + "payment/invoice/taskcenter-receive-mismatch/";
URLS.SendInvoiceAPI = environment.BASE_API_URL + "payment/invoice/taskcenter/send";

// Load
URLS.GetLoadData = environment.BASE_API_URL + "logistics/load/find-by-po/";
URLS.GetLoadDataTransplace = environment.BASE_API_URL + "logistics/load/find-by-po-transplace/";


// URLS. = environment.BASE_API_URL + "";
// URLS. = environment.BASE_API_URL + "";

// Core

URLS.ValidateTokenAPI = environment.BASE_API_URL + "admin/api/user/public/validate-token/";
URLS.UpdateUserPasswordAPI = environment.BASE_API_URL + "admin/api/user/public/save-password";
URLS.ForgotPassword = environment.BASE_API_URL + "admin/api/user/public/forgot-password";

//My preferences
URLS.GetFetchNotifications = environment.BASE_API_URL + "admin/api/user/preferences/notifications/fetch/";
URLS.SaveEmailNotification = environment.BASE_API_URL + "admin/api/user/preferences/notifications/save-preferences";
URLS.GetPartners = environment.BASE_API_URL + "admin/api/user/preferences/notifications/fetch-preferred-partners/";
URLS.SavePartners = environment.BASE_API_URL + "admin/api/user/preferences/notifications/save-preferred-partner";

URLS.UpdateUserProfile = environment.BASE_API_URL + "admin/api/user/updateUserProfile";
URLS.ChangeUserProfilePasswordAPI = environment.BASE_API_URL + "admin/api/user/change-password";
URLS.GetUser = environment.BASE_API_URL + "admin/api/user/fetchUser/";
URLS.GetUserProfile = environment.BASE_API_URL + "admin/api/user/me";
URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";

//pricing and Product Template
URLS.serchPriceList = environment.BASE_API_URL + ''
URLS.getUserComodities = environment.BASE_API_URL + 'catalog/api/catalog/my-commodities'
URLS.GetCatalogListFromCommoditiesDemand = environment.BASE_API_URL + "catalog/api/catalog/catalogForUserDepartment?commodityIds=";
URLS.GetProductListByTemplateId = environment.BASE_API_URL + "catalog/api/product-template/item/list/";
URLS.deleteProductFromTemplate = environment.BASE_API_URL + "catalog/api/product-template/item/delete?ids="
URLS.suggestProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/search/suggest/"
URLS.ListProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/list"
URLS.UpdateProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/update"
URLS.CreateProductTemplate = environment.BASE_API_URL + "catalog/api/product-template/create"
URLS.GetTemplateType = environment.BASE_API_URL + "catalog/api/product-template/template-type"
URLS.GetTemplateById = environment.BASE_API_URL + "catalog/api/product-template/"
URLS.GetMyDepartmentUsers = environment.BASE_API_URL + "admin/api/user/my-department-users"
URLS.updateTemplateStatus = environment.BASE_API_URL + "catalog/api/product-template/update-status"
URLS.TemplateNameExist = environment.BASE_API_URL + "catalog/api/product-template/name-exists/"
URLS.suggestTemplate = environment.BASE_API_URL + "catalog/api/product-template/search/suggest/";
URLS.GetMyDepartmentProducts = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-my-catalog-code-desc/"
URLS.SuggestsubstitueProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/get-substitute-products/";
URLS.CreateSubstitueProduct = environment.BASE_API_URL + "order/sup/po/substitute-catalog-item";
URLS.GetComment = environment.BASE_API_URL + "catalog/comment/supplier/list/";
URLS.suggestPriceReqsTemplates = environment.BASE_API_URL + "catalog/api/product-template/price-req-product-template/search/suggest/";


//pricing

URLS.serchPriceList = environment.BASE_API_URL + '';
URLS.GetPriceType = environment.BASE_API_URL + 'catalog/api/product-pricing/price-types';
URLS.GetProductSelectionFromList = environment.BASE_API_URL + 'catalog/api/product-pricing/product-select-from';
URLS.GetTemplateOrCommodityList = environment.BASE_API_URL + 'catalog/api/product-template/populate-dropdown-on-product-select-from';
URLS.GetShipFromList = environment.BASE_API_URL + 'admin/api/org/businessUnits/SHIP_FROM';
URLS.getDeleveryToList = environment.BASE_API_URL + 'admin/api/org/businessUnits';
URLS.GetBuyerList = environment.BASE_API_URL + 'admin/api/trading-partners/my-trading-partners/buy-sell/BUYER';
URLS.GetMyTradingPartnersNewAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners-new/buy-sell/";
URLS.GetTemplateOrCommodityDetails = environment.BASE_API_URL + 'catalog/api/product-pricing/get-template-or-commodity-details/';
URLS.GetTemplateList = environment.BASE_API_URL + 'catalog/api/product-pricing/price-sheet-list';
URLS.CreatePriceSheet = environment.BASE_API_URL + 'catalog/api/product-pricing/create-pricing-sheet';
URLS.IsPriceSheetExists = environment.BASE_API_URL + 'catalog/api/product-pricing/is-price-sheet-exists/';
URLS.GetPriceSheet = environment.BASE_API_URL + 'catalog/api/product-pricing/get-price-sheet/';
URLS.GetPriceRequestsDetails = environment.BASE_API_URL + 'catalog/api/product-pricing/supplier/price-requests-details/';
URLS.ChangeStatusSheet = environment.BASE_API_URL + 'catalog/api/product-pricing/update-status';
URLS.UpdatePriceSheet = environment.BASE_API_URL + 'catalog/api/product-pricing/update-pricing-sheet';
URLS.getUnMappedTradingPartners = environment.BASE_API_URL + 'catalog/api/product-pricing/get-un-mapped-trading-partners';
URLS.getPriceRequestList = environment.BASE_API_URL + 'catalog/api/product-pricing/price-requests-list-sup';
URLS.UpdatePriceRequestDetails = environment.BASE_API_URL + 'catalog/api/product-pricing/supplier/price-requests-details/update';
URLS.GetMyPricing = environment.BASE_API_URL + 'catalog/api/product-pricing/fecth-supplier-lowest-pricing-for-pricing-request/';
URLS.GetMyPricingForRequestPricing = environment.BASE_API_URL + 'catalog/api/product-pricing/fecth-supplier-lowest-pricing-for-pricing-request';



URLS.DownloadFile = environment.BASE_API_URL + "";
URLS.POLineFileUpload = environment.BASE_API_URL + "order/api/document/line/upload?id=";
URLS.POFileUpload = environment.BASE_API_URL + "order/api/document/po/upload?id=";
URLS.FetchPOLevelFiles = environment.BASE_API_URL + "order/api/document/po/all/";
URLS.FetchPOLineLevelFiles = environment.BASE_API_URL + "order/api/document/line/all/";
URLS.DeletePODocument = environment.BASE_API_URL + "order/api/document/delete?objectId=";
URLS.GetPOFileObjectDocument = environment.BASE_API_URL + "order/api/object-store/download/";
URLS.GetPOFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";

//watchlist
URLS.GetWatchList = environment.BASE_API_URL + "order/po/taskcenter/watchlist";
URLS.AddToWatchList = environment.BASE_API_URL + "order/po/saveUserPoFlag";
URLS.DeleteFromWatchList = environment.BASE_API_URL + "order/po/delete?";

URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetUserDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";

URLS.setIndicatorAsViwedAPI = environment.BASE_API_URL + "order/po/indicator/delete";


URLS.DownloadOrderGuideTemplate = environment.BASE_API_URL + "catalog/api/catalog/download-orderguide-template";
URLS.UploadOrderGuideTemplate = environment.BASE_API_URL + "catalog/api/catalog/upload-orderGuide";


URLS.COMMENTS = environment.BASE_API_URL + "order";

//Report
URLS.DownloadInvoiceReport = environment.BASE_API_URL + "payment/api/reports/sup/download/invoice-report";
URLS.GetInvoiceReport = environment.BASE_API_URL + "payment/api/reports/sup/invoice-report";
URLS.GetOrderReport = environment.BASE_API_URL + "order/api/reports/sup/order-details";
URLS.DownloadOrderReport = environment.BASE_API_URL + "order/api/reports/sup/download/order-details";
URLS.GetMyTradingPartnersNewAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners-new/buy-sell/";
URLS.GetMapProductReportAPI = environment.BASE_API_URL + "catalog/api/catalog/report/mapped-products-details";
URLS.DownloadMapProductReport = environment.BASE_API_URL + "catalog/api/catalog/report-download-mapped-products-details";
URLS.GetPartnerCatalogReportAPI = environment.BASE_API_URL + "catalog/api/catalog/report/partner-catalog-details";
URLS.DownloadPartnerCatalogReport = environment.BASE_API_URL + "catalog/api/catalog/report-download-partner-catalog-details";


// Catalog

URLS.getProductListAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemList"
URLS.AddUpdateProductAPI = environment.BASE_API_URL + "catalog/api/catalog/addUpdate";
URLS.GetProductAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemPrimary/";
URLS.GetCatalogCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/topCategories";
URLS.GetCatalogSubCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/subCategories/";
URLS.GetCatalogCountryCodesAPI = environment.BASE_API_URL + "catalog/api/catalog/origin";
URLS.GetUOMAPI = environment.BASE_API_URL + "catalog/api/catalog/uom/";
URLS.GetGrowingMethodsAPI = environment.BASE_API_URL + "catalog/api/catalog/growingMethod";
URLS.DownloadFileAPI = environment.BASE_API_URL + "catalog/api/catalog/downloadTemplate";
URLS.DownloadCommodityFileAPI = environment.BASE_API_URL + "catalog/api/catalog/download-commodity-template";
URLS.GetPackTypesAPI = environment.BASE_API_URL + "catalog/api/catalog/packType";
URLS.GetProductDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/";
URLS.SuggestProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMyTradingPartnersAPI = environment.BASE_API_URL + "admin/api/trading-partners/buy-sell/my-trading-partners/false";
URLS.GetMyTradingPartnersNewAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners-new/buy-sell/";
URLS.GetAllSubCategoriesAPI = environment.BASE_API_URL + "catalog/api/catalog/allSubCategories/";
URLS.GetPartnerCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerCatalogItems";
URLS.GetPartnerCatalogItemsChipListAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerProducts";
URLS.GetPartnerCatalogItemsByDescAPI = environment.BASE_API_URL + "catalog/api/catalog/getCatalogItemByDesc";
URLS.MapCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mapCatalogItem";
URLS.SearchProductAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMappedCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mappedCatalogItems";
URLS.SearchOrderAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.CheckProductExistAPI = environment.BASE_API_URL + "catalog/api/catalog/check-product-code-exist/";
URLS.CheckGTINExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-if-gtin-exist";
URLS.CheckUPSCExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-upc-exist/";
URLS.DownloadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/download-partners-catalog";
URLS.UploadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/upload-partners-catalog";
URLS.SuggestCodeDescAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc/";
URLS.GetFileObject = environment.BASE_API_URL + "admin/api/object-store/";
URLS.UploadCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/uploadCatalog";
URLS.UploadCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/upload-commodity";
URLS.SearchProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc-active/";
URLS.SearchMappingProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-products-on-code-desc/";
URLS.IsPrimaryProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/update-mapped-catalog-item";
URLS.GetCommoditiesOnDepartmentsAPI = environment.BASE_API_URL + "catalog/api/catalog/commodity-by-depts?deptCodes=";
URLS.GetCommodityExistOrNotAPI = environment.BASE_API_URL + "catalog/api/catalog/check-commodity-exist/";
URLS.AppModuleAccessAPI = environment.BASE_API_URL + "admin/api/access/is-business-rule-granted";
//(RP)PMB-6593 for sync mapping through update open orders
URLS.GetSyncProduct = environment.BASE_API_URL + "catalog/api/catalog/sync-map-catalog-item"

// Growing Region
URLS.AddGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/create-growing-region";
URLS.FetchGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-growing-region/";
URLS.UpdateGrowingRegionAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/update-growing-region";
URLS.FetchGrowingRegionListAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/fetch-growing-region-list";
URLS.FetchGrowingLotListAPI = environment.BASE_API_URL + "catalog/api/catalog/pti/growing-region-list";
URLS.FetchPMARegionListAPI = environment.BASE_API_URL + "catalog/api/catalog/pma-growing-regions";

// Charges 
URLS.GetChargesListAPI = "admin/api/charge/list";
URLS.GetChargeSettingsAPI = "order/po/charge/setting/";
URLS.AddUpdatePOLineChargesAPI = "order/po/line/charge";

URLS.AddUpdatePOHeaderChargesAPI = "order/po/charge";
//(PM) PMB-10447 PO charges should be visible on Invoices and editable
URLS.GetMappedChargesListAPI = "admin/api/charge/mapped-charges/list/";

URLS.AddUpdatePOHeaderChargesAPI = "order/po/charge";

//Tack and trace
URLS.TrackTraceSerach = environment.BASE_API_URL + "order/sup/product/search";
URLS.TrackTraceList = environment.BASE_API_URL + "order/sup/product/trace";
URLS.DownloadStoreShipment = environment.BASE_API_URL + "order/api/store-shipment/download-store-order-template";
URLS.GetStoreShipmentFile = environment.BASE_API_URL + "order/api/store-shipment/list";
URLS.SuggestStoreShipmentFile = environment.BASE_API_URL + "order/api/store-shipment/search/suggest/";
URLS.UploadStoreShipmentsAPI = environment.BASE_API_URL + "order/api/store-shipment/upload-po-shipments";
URLS.GetFileObjectDocument = environment.BASE_API_URL + "admin/api/object-store/download/";
URLS.GetDeliveryLocations = environment.BASE_API_URL + "admin/api/org/get-delivery-info/"
//Elastic search
URLS.getOrderListFromElasticSearch = environment.BASE_API_URL + "search/purchase-order/sup-list";
URLS.SearchOrderByFiltersElasticAPI = environment.BASE_API_URL + "search/purchase-order/sup-search/suggest/by-filter/";
URLS.GlobalSerachResult = environment.BASE_API_URL + 'search/facets';

//PMB-9901 Implement the item code search option similar to the buyer side on supplier order details report
URLS.SearchProductCodeByFiltersAPI = environment.BASE_API_URL + "order/sup/search/suggest-productcode/by-filter/";
URLS.SearchBuyerProductCodeByFiltersAPI = environment.BASE_API_URL + "order/buy/search/suggest-productcode/by-filter/";
