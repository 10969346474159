/***********************************************************************
* DESCRIPTION :
* Implement Global Order Search in Supplier/Vendor Applications
* NOTES :
* AUTHOR :    Payal Mandavkar      CREATE DATE :    26 March 2024
* CHANGES : Added new component
* REF NO          VERSION   DATE         WHO             DETAIL
* PMB-8552        2.6.0     26/03/2024   Hitesh Ahire    Implement Global Order Search in Supplier/Vendor Applications
************************************************************************/

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { fadeIn } from '@procurant/sharedcomponent/extras/animation';
import { ParsePipes } from 'common-lib';
@Component({
  selector: 'order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
  animations:[fadeIn]
})
export class OrderCardComponent implements OnInit {

  @Input() order: any;
  @Input() crossDockFacility: boolean = false;
  @Input() searchText: any;
  @Output() openNewTabEvent: EventEmitter<any> = new EventEmitter<any>();
  public isMoreThanFive: boolean = false;

  @Input() isLoader: boolean = false;

  constructor(private datePipe: ParsePipes) { }

  ngOnInit(): void { 
  }


  // list section code
  public getStatusClasses(order): String {
    const statusMap = {
      "New": 'new',
      "Modified": 'modified',
      "Shipped Modified": 'modified',
      "Shipped Modified - To CD": 'modified',
      "Received Reconciled": 'modified',
      "Draft Reconciled": 'modified',
      "Cancelled": 'cancelled',
      "Received Exception": 'cancelled',
      "Acknowledged": 'acknowledged',
      "Shipped": 'acknowledged',
      "Shipped - To CD": 'acknowledged',
      "Received": 'acknowledged',
      "Draft": 'acknowledged',
      "buyer-modified": "buyer-modified"
    };

    if (order?.source?.substatus == "Buyer Modified" || order?.source?.substatus == "Buyer Reconciled" || order?.source?.substatus == "Buyer Supplier Modified") {
      return statusMap["buyer-modified"] || '';
    }
    return statusMap[order?.source?.postatus] || '';
  }

  openNewTab(order) {
    this.openNewTabEvent.emit(order?.source);
  }

  public getShipDate(order) {
    if (order?.source?.minshipdate && order?.source?.maxshipdate) {
      const minDate = this.datePipe.transform(order?.source?.minshipdate, 'date');//this.datePipe.transform(order.minshipdate, 'yyyy/MM/dd');
      const maxDate = this.datePipe.transform(order?.source?.maxshipdate, 'date');//this.datePipe.transform(order.maxshipdate, 'yyyy/MM/dd');
      return minDate === maxDate ? minDate : `${minDate} - ${maxDate}`;
    } else {
      return this.datePipe.transform(order?.source?.shipdate, 'date');
    }
  }

  public isProductSearch(order) {
    //const elementsToCheck = ['buyerproduct.code', 'buyerproduct.description', 'buyerproduct.code.keyword', 'buyerproduct.description.keyword', 'sellerproduct.code', 'sellerproduct.code.keyword', 'sellerproduct.description', 'sellerproduct.description.keyword'];
    const elementsToCheck = ['supplier_po_search_composite_field', 'supplier_po_search_composite_field.standard', 'supplier_po_search_composite_field.ngram', 'buyerproduct.code', 'buyerproduct.code.keyword', 'sellerproduct.code', 'sellerproduct.code.keyword'];
    // Check if any of the elements exist in the 'highlight' section
    return elementsToCheck.some(element => order?.highlight?.hasOwnProperty(element));
  }


  public getHighlightList(order: any) {
    let allValues: any = [];
    const highlight = order?.highlight;
    if (highlight) {
      allValues = Object.values(highlight).reduce((acc: string[], val: any) => {
        if (typeof val === 'string') {
          acc.push(val);
        } else if (Array.isArray(val)) {
          acc = acc.concat(val.filter((item) => typeof item === 'string'));
        }
        return acc;
      }, []);
  
      // Normalize strings to remove HTML tags for comparison
      const normalizeString = (str: string) => str.replace(/<[^>]*>/g, '').trim();
  
      // Use a Set to remove duplicates based on normalized strings
      const uniqueNormalizedStrings = new Map<string, string>();
      allValues.forEach((str) => {
        const normalized = normalizeString(str);
        if (!uniqueNormalizedStrings.has(normalized)) {
          uniqueNormalizedStrings.set(normalized, str);
        }
      });
  
      // Convert back to an array of unique values
      allValues = Array.from(uniqueNormalizedStrings.values());
      this.isMoreThanFive = allValues.length >= 5 ? true : false;
      return allValues;
    }
    return [];
  }
  


  // public getProductList(order) {
  //   return order?.source?.buyerproduct?.filter(item => {
  //     return item.code.toLowerCase().includes(this.searchText.toLowerCase()) ||
  //       item.description.toLowerCase().includes(this.searchText.toLowerCase());
  //   });
  // }

}
