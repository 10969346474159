<!-- <div fxLayout="column" fxFlex="100%;" fxLayoutAlign="space-between" [formGroup]="editForm">
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="30%;">
            <div style="padding-top: 8px;" class="textLeft">
                <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGES' | translate }}</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;" class="mt-5">
            <span> {{editForm.controls.chargeName.value}} | {{editForm.controls.chargeTypeCode.value}}
                {{editForm.controls.freight.value?'- Freight':''}}</span>
        </div>
    </div>

    <div fxLayout="row">
        <div fxLayout="column" fxFlex="30%;">
            <div class="textLeft pt-20">
                <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_AMOUNT_RATE' | translate }}
                    ({{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'}})*</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
            <mat-form-field>
                <input matInput maxlength="10" placeholder="" formControlName="chargeAmt" (input)="changeValue()"
                    fourdecimalPlace>
                <mat-error *ngIf="editForm.controls['chargeAmt'].hasError('pattern') ">
                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR_VALID' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="!editForm.controls['chargeAmt'].hasError('required') && !editForm.controls['chargeAmt'].hasError('max') && editForm.controls['chargeAmt'].hasError('min')">
                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ZERO_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="!editForm.controls['chargeAmt'].hasError('required') && !editForm.controls['chargeAmt'].hasError('min') && editForm.controls['chargeAmt'].hasError('max')">
                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_BETWEEN_ERROR' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="editForm.controls['chargeAmt'].hasError('required')  && !editForm.controls['chargeAmt'].hasError('pattern') && !editForm.controls['chargeAmt'].hasError('min') && !editForm.controls['chargeAmt'].hasError('max')">
                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR' | translate }}
                </mat-error>
            </mat-form-field>

        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="30%;">
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
            <mat-radio-group formControlName="applyByCode" (change)="changeValue()">
                <mat-radio-button value="FLAT">Flat</mat-radio-button>&nbsp;
                <mat-radio-button value="PER_PACKTYPE">Per Packtype</mat-radio-button>&nbsp;
                <mat-radio-button value="PER_WEIGHT">Per Weight (LB/Kg)</mat-radio-button>
                <mat-error *ngIf="editForm.controls['applyByCode'].hasError('required')">
                    {{ 'CHARGE.MESSAGE.APPLY_BY_REQUIRED_ERROR' | translate }}
                </mat-error>
            </mat-radio-group>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="30%;">
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
        </div>
    </div>
    <div fxLayout="row" *ngIf="isShowProrateToLine" class="pt-18">
        <div fxLayout="column" fxFlex="30%;">
            <div class="textLeft pt-20">
                <span class="fieldLabel"> {{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate }}*</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
            <mat-form-field class="formContainer">
                <mat-select formControlName="prorateByCode" (selectionChange)="changeValue()">
                    <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let proratedBy of chargePRORATE_BY" [value]="proratedBy.code">
                        {{proratedBy.value}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.controls['prorateByCode'].hasError('required')">
                    {{ 'CHARGE.MESSAGE.PRORATED_BY_REQUIRED_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" *ngIf="order.controls.inclProdCost.value">
        <div fxLayout="column" fxFlex="30%;">
            <div style="padding-top: 20px;" class="textLeft">
                <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_INCLUDED_IN_PC' | translate }}</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;" style="padding-top: 20px;">
            <span class="fieldLabel">{{'BUTTON.YES' | translate}}</span>
        </div>
    </div>
    <div fxLayout="row" class="pt-20">
        <div fxLayout="column" fxFlex="30%;">
            <div class="textLeft">
                <span class="fieldLabel">{{ 'CHARGE.LABEL.DISPLAY_TO' | translate }}</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
            <span class="fieldLabel">{{editForm.controls.displayToAppCode.value}}</span>
        </div>
    </div>
    <div fxLayout="row" class="pt-20">
        <div fxLayout="column" fxFlex="30%;">
            <div class="textLeft">
                <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_CODE_PLAIN' | translate }}</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5%;">
        </div>
        <div fxLayout="column" fxFlex="65%;">
            <span class="fieldLabel"> {{editForm.controls.chargeCode.value}}</span>

        </div>
    </div>
</div> -->
<!-- (RD)PMB-7504 supplier order details UI change -->
<div class="chargeEditDetails" [formGroup]="editForm">
    <div class="chargeSection">
        <div class="leftSection">
            <div class="inputfield">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CHARGE.LABEL.CHARGES' | translate }}</mat-label>
                    <input position="bottom" kendoTooltip title="{{editForm.controls['name'].value}}" class="inputlable"
                        matInput placeholder="{{ 'CHARGE.LABEL.CHARGES' | translate }}" formControlName="name" readonly>
                </mat-form-field>
            </div>
            <div class="inputfield" *ngIf="editForm.controls['tariffRate'].value!=null">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CHARGE.LABEL.TARIFF_RATE' | translate }}%</mat-label>
                    <input class="inputlable" matInput placeholder="{{ 'CHARGE.LABEL.TARIFF_RATE' | translate }}"
                        formControlName="tariffRate" readonly>
                </mat-form-field>
            </div>
            <div class="amount-radio-row-section">
                <div class="inputfield">
                    <mat-form-field appearance="outline">
                        <mat-label>Amount/Rate ($)<span>*</span></mat-label>
                        <input class="inputlable" matInput maxlength="10" placeholder="Amount/Rate ($)*"
                            [readonly]="editForm.controls['tariffRate'].value!=null"
                            prefix="{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL'|  pipes:'currancySymbol'}}" mask="separator.4"
                            thousandSeparator="," separatorLimit="9999" decimalMarker="." formControlName="chargeAmt"
                            (input)="changeValue()">
                        <mat-error *ngIf="editForm.controls['chargeAmt'].hasError('pattern') ">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR_VALID' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!editForm.controls['chargeAmt'].hasError('required') && !editForm.controls['chargeAmt'].hasError('max') && editForm.controls['chargeAmt'].hasError('min')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ZERO_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!editForm.controls['chargeAmt'].hasError('required') && !editForm.controls['chargeAmt'].hasError('min') && editForm.controls['chargeAmt'].hasError('max')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_BETWEEN_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="editForm.controls['chargeAmt'].hasError('required')  && !editForm.controls['chargeAmt'].hasError('pattern') && !editForm.controls['chargeAmt'].hasError('min') && !editForm.controls['chargeAmt'].hasError('max')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="radio-section">
                    <mat-radio-group formControlName="applyByCode" (change)="changeValue()"
                        [disabled]="editForm.controls['tariffRate'].value!=null">
                        <mat-radio-button disableRipple value="FLAT">Flat</mat-radio-button>&nbsp;
                        <mat-radio-button disableRipple value="PER_PACKTYPE">Per Packtype</mat-radio-button>&nbsp;
                        <mat-radio-button disableRipple value="PER_WEIGHT">Per Weight (LB/Kg)</mat-radio-button>
                        <mat-error *ngIf="editForm.controls['applyByCode'].hasError('required')">
                            {{ 'CHARGE.MESSAGE.APPLY_BY_REQUIRED_ERROR' | translate }}
                        </mat-error>
                    </mat-radio-group>
                </div>

            </div>
            <div class="inputfield" *ngIf="isShowProrateToLine">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate }}*</mat-label>
                    <mat-select placeholder="{{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate }}*"
                        formControlName="prorateByCode" (selectionChange)="changeValue()"
                        [disabled]="editForm.controls['tariffRate'].value!=null">
                        <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                        <mat-option *ngFor="let proratedBy of chargePRORATE_BY" [value]="proratedBy.code">
                            {{proratedBy.value}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.controls['prorateByCode'].hasError('required')">
                        {{ 'CHARGE.MESSAGE.PRORATED_BY_REQUIRED_ERROR' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div>
                <mat-label>
                    {{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}
                </mat-label>
                <span class="fieldLabel">{{editForm.controls.displayToAppCode.value}}</span>
            </div> -->


        </div>

    </div>
    <div class="displayToSection">
        <div class="displayLeftSection">
            <div class="field-section">
                <div class="fieldLabel">{{ 'CHARGE.LABEL.DISPLAY_TO' | translate }} </div>
                <div class="fieldValue">{{editForm.controls.displayToAppCode.value}}</div>
            </div>
        </div>
        <div class="displayRightSection">
            <div class="field-section">
                <div class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_CODE_PLAIN' | translate }} </div>
                <div class="fieldValue">{{editForm.controls.chargeCode.value}}</div>
            </div>
        </div>
    </div>
</div>